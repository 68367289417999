import React from 'react';
import { navigate } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core/';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import MyButton from '../../tools/MyButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import json2mq from 'json2mq';

const useStyles = makeStyles((theme) => ({
    container: {
      width: '100%',
      margin: '20px 0',
      position: 'relative',
      display: 'table'
    },
    tableCell: {
      position: 'relative',
      display: 'table-cell',
      verticalAlign: 'middle',
      padding: '55px 0',
      zIndex: 2
    },
    tableMargin: {
      paddingLeft: 55,
      paddingRight: 55,
      textAlign: 'center'
    },
    title: {
      textShadow: '0 0 4px rgb(0 0 0 / 40%)',
      color: 'white',
      marginBottom: 8
    },
    subtitle: {
      textShadow: '0 0 4px rgb(0 0 0 / 40%)',
      color: 'white',
      marginBottom: 8,
      maxWidth: '75%',
      margin: '0 auto'
    },
    shadow: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: '#000'
    }
}));

const BackgroundSection = ({imageFluid, title, subtitle, height, opacity, showButton, titleButton, linkButton }) => {
  const classes = useStyles()
  const isDesktop = useMediaQuery(
    json2mq({
      maxWidth: 750,
    })
  );

  const handleClick = () => {
    navigate(linkButton)
  }

  return (
    <BackgroundImage
      style={{height: height}}
      className={classes.container}
      Tag="section"
      fluid={imageFluid}
      backgroundColor={`#040e18`}
    >
    <div className={classes.tableCell}>
      <div className={classes.tableMargin}>
        <Typography style={isDesktop ? {fontSize: 34} : {fontSize: 54}} className={classes.title} variant="h6" component="h2">{title}</Typography>
        <Typography style={isDesktop ? {fontSize: 18} : {fontSize: 28}} className={classes.subtitle} variant="h6" component="h2">{subtitle}</Typography>
        { showButton && <MyButton onClick={handleClick} style={{minWidth: 100, marginTop: 27}}>{titleButton}</MyButton>}
      </div>
    </div>
    <div className={classes.shadow} style={{opacity: opacity}}></div>
    </BackgroundImage>
  )
}

const FeatureSection = styled(BackgroundSection)`
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`

export default FeatureSection